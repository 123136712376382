import React from 'react'
import { graphql } from 'gatsby'

// Components
import {
  TitleDefault,
  BannerDefault,
  ContentDefault,
  BoxDefault,
} from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import CallToAction from 'components/CallToAction'

import 'styles/templates/PageTemplate.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path
      acf {
        textTop: text_top
        textBottom: text_bottom

        callToActionTitle: call_to_action_title
        callToActionSubtitle: call_to_action_subtitle
        callToActionButtonText: call_to_action_button_text
        callToActionButtonLocation: call_to_action_button_location

        backgroundImage: background_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 800) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 800, toFormat: JPG) {
                src
              }
            }
          }
        }
      }
      yoast_meta {
        name
        content
        property
      }
    }
  }
`

const Page = ({
  data: {
    page: { title, path, acf: fields, yoast_meta },
  },
}) => {
  return (
    <Layout backgroundImage={fields.backgroundImage}>
      {fields.backgroundImage && fields.backgroundImage.localFile ? (
        <SEO
          yoast={{ meta: yoast_meta }}
          pathname={path}
          image={fields.backgroundImage.localFile.childImageSharp.fixed.src}
          article
        />
      ) : (
        <SEO yoast={{ meta: yoast_meta }} pathname={path} />
      )}
      <div>
        <BannerDefault className="page-template py-5">
          <div className="row">
            <div className="col-12 col-lg-6 py-4">
              <TitleDefault asH1 className="pl-lg-5">
                {title}
              </TitleDefault>
            </div>
            <div className="col-12 col-lg-6" />
          </div>
        </BannerDefault>

        <div className="page-template">
          <div className="container pb-5">
            <BoxDefault>
              <ContentDefault className="py-4 px-3 px-lg-5">
                <div dangerouslySetInnerHTML={{ __html: fields.textTop }} />
              </ContentDefault>
            </BoxDefault>
          </div>
        </div>

        {fields.callToActionTitle && fields.callToActionButtonText && (
          <div className="my-5 pb-5">
            <CallToAction
              title={fields.callToActionTitle}
              subtitle={fields.callToActionSubtitle}
              buttonText={fields.callToActionButtonText}
              buttonLocation={fields.callToActionButtonLocation}
            />
          </div>
        )}

        {fields.textBottom && (
          <div className="page-template">
            <div className="container pb-5">
              <BoxDefault className="mt-5">
                <ContentDefault className="py-4 px-3 px-lg-5">
                  <div
                    dangerouslySetInnerHTML={{ __html: fields.textBottom }}
                  />
                </ContentDefault>
              </BoxDefault>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Page
